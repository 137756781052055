
import { useQuery, useReactiveVar } from '@apollo/client'
import { cardLink, cardView, userDatas } from "@app/hoc/cache";
import { Fragment } from 'react';

const Editor = () => {


  const {dev:dev} = useReactiveVar(cardLink); // refresh UI by value
  const userData = useReactiveVar(userDatas); // refresh UI by value
/*
  const { error, data, loading } = useQuery<UserQuery>(userGQL, {
    fetchPolicy: 'cache-and-network',
  });
  if (!data) {
    // we don't have data yet

    if (loading) {
      // but we're loading some
      return <h2>Loading initial data...</h2>;
    }
    if (error) {
      // and we have an error
      return <h2>Error loading data :(</h2>;
    }
    return <h2>Unknown error :(</h2>;
  }
*/
  const doEditor = () =>  {
    let host = 'https://fe.liflo.io';
    if( dev ) host = 'http://localhost:3000';
    const url = host + '/flow/'+userDatas().cfid;
    //const url = host + '/card/0/'+cardLink().editcardid;
    window.open(url, '_blank', "height=800,width=1100");
  }

  if( userDatas().cfid !== '')
    return (
      <div className="editor" onClick={doEditor}>
          <div>
            edit : {userData.fixed===true?1:0}
          </div>
      </div>
    );
  else
    return <Fragment/>;
};

export default Editor;
//{cardView().width} x {cardView().height} : {window.innerWidth} x {window.innerHeight}
// {cardView().width} x {cardView().height} : {window.innerWidth} x {window.innerHeight}
//userData.cid;        

// 34.102.136.180