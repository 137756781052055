
import "./style.css";

import Box from '@mui/material/Box';
import Examination from "./Examination";
import useResize from "@app/hook/useResize";
import { userDatas } from "@app/hoc/cache";


interface ResizableFrameProps {
  src: string;
  title: string;
  path: string;
  cid: string;
  status: number;
  nextCard: any;
  iframeStyles?: Record<string, unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

const Exam = ({
  src,
  title,
  path,
  cid,
  status,
  nextCard,
  iframeStyles = {},
  ...rest
}: ResizableFrameProps): JSX.Element => {


  //let cWidth = cardView().width;
  //let cHeight = cardView().height;
  //const { width : cWidth, height: cHeight } = useResize();
  const { width : cWidth, height: cHeight } = userDatas();

  return (
      <Box component="div" height={(cHeight-80) + 'px'} width={(cWidth-20) + 'px'}>
        <Examination status={status} src={src} nextCard={(cIdx:number)=>nextCard(cIdx)}/>
      </Box>
  );
};

// maxWidth={viewportWidthMap[viewportWidth]}
export default Exam;




