import {useRef} from "react";

import {
    useMutation,
    useQuery,
    useLazyQuery,
    useReactiveVar,
} from "@apollo/client";


import "./style.css";
import BaseCard from "./BaseCard";
import { cardView, userDatas } from "@app/hoc/cache";
import useResize from "../../hook/useResize";
import { getCardGQL } from "@app/hoc/gql";

interface CardProps {
    card: {
      id?: string;
      type?:string;
      meta?: Array<{ title: string; subtitle: string }>;
    };
  }



const Card = (props: CardProps) => {

    const { card } = props;
    //const { width : cWidth, height: cHeight } = useResize();
    const { width : cWidth, height: cHeight } = userDatas(); //useReactiveVar(userDatas);

    const { loading, error, data, refetch, networkStatus } = useQuery(
        getCardGQL,
        {
          variables: { "id": card.id },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only'
          // pollInterval: 500
        }
    );

    if (networkStatus === 4) return <p>Refetching!</p>;
    if (loading) return <p>loading</p>;
    if (error) return <p>Error!: {error.message}</p>;
    
    const onSwitch = (cardId: any) => {
        if( cardId !== ''){

            //const url = 'http://localhost:3006/card/'+cardId;
            const url = '/card/'+cardId;
            window.open(url, '_blank',"height=800,width=550");  
            //if( idx !== undefined )
            //  handleClick(idx);
        }
    }

    const carddata = data.getCard.card;

    let leftMargin = (window.innerWidth - cWidth)/2;
    let topMargin = (window.innerHeight - cHeight)/2;

    return (
        <div style={{ position: "absolute", top:topMargin, left:leftMargin, width: cWidth, height: cHeight }}>
        <div >
            <BaseCard card={carddata} ctype={1} width={cWidth} height={cHeight} onCaseSelect={(idx:any) => onSwitch(idx)}/>
        </div>
        </div>
    ) 
}

export default Card;
