import { memo, useEffect, useRef, useState } from "react";

import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import { cardLink, cardView, userDatas } from "@app/hoc/cache";
import { getFlowGQL, getUserByToken } from "@app/hoc/gql";

import { Transition } from "@headlessui/react"
import { isFullScreen, isLocal } from "../Exam/containers/GameFullScreen";
import { Button, Typography } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { setSize } from "@app/constants/YMData";

export const generateUUID = () =>{ // Public Domain/MIT
  var d = new Date().getTime();//Timestamp
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if(d > 0){//Use timestamp until depleted
          r = (d + r)%16 | 0;
          d = Math.floor(d/16);
      } else {//Use microseconds since page-load if supported
          r = (d2 + r)%16 | 0;
          d2 = Math.floor(d2/16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

export const getUserDataBefore = async() => {
  if( userDatas().uid === '') return false;
  let apiServer = 'https://m6.liflo.io'; // 
  if( isLocal() ) apiServer = 'http://localhost:8787'; // 'https://m5.liflo.io'

  const url = apiServer + '/userdata/'+userDatas().uid;
  
  let response = await fetch(url);
  let data = await response.json();
  const udata = await JSON.parse(data.userData);
  //console.log('getUserData:'+udata);
  if( data.userData !== '' ){
    userDatas().data = udata;
    if( userDatas().data.user === undefined )
      getRegisteredUserData();
    return true;
  }
  else       
    return false;      
}

// for jinju ====================================================
export const getUserData = async() => {
  if( userDatas().uid === '') return false;
  let apiServer = 'https://m6.liflo.io'; // 
  //if( isLocal() ) apiServer = 'http://localhost:8787'; // 'https://m5.liflo.io'
  const url = apiServer + '/userdata/'+userDatas().uid;
  
  let response = await fetch(url);
  let data = await response.json();
  const udata = await JSON.parse(data.userData);
  userDatas().data = udata;
  //console.log('request getUser -----------------------'+udata.user);

  //console.log('getUserData:'+udata);
  if( udata.user === undefined )
    await getRegisteredUserData();
  
  userDatas({...userDatas(), update: userDatas().update+1});
  //userDatas().update = userDatas().update+1;
  
}

export const getRegisteredUserData = async() => {
  //console.log('userData:'+userDatas().data.user);
  let id = userDatas().index;
  if( id === undefined || userDatas().data.user !== undefined ) return;

  const url = 'https://jj.mbisq.com/data/jinju/s/'+id+'.json';

  let response = await fetch(url);
  let data = await response.json();
  //console.log('request getRegisterdUser -----------------------'+data);
  userDatas().data.user = data.user;
  await setUserData();  
  //userDatas({...userDatas(), data: {user:data.user}});
  //console.log('userData2:'+userDatas().data.user);
} 

export const setUserData = async () => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userDatas().data)
  };
  //console.log('write userData:'+requestOptions.body)
  let apiServer = 'https://m6.liflo.io'; // 
  //if( isLocal() ) apiServer = 'http://localhost:8787'; // 'https://m5.liflo.io'
  await fetch(apiServer+'/put/'+userDatas().uid, requestOptions)
      .then(response => response.json());
  //console.log('set UserData -----------------------')
}

// ==============================================================


export const headerHeight = 47;
export const Header = memo(
  ({ curr, onGoCard }: { curr: 'plan' | 'user' | 'other', onGoCard:any }) => {

    const userData = useReactiveVar(userDatas); // refresh UI by value
    const cardLinkData = useReactiveVar(cardLink); // refresh UI by value

    const [showMenu, setShowMenu] = useState(false)
    const [name, setName] = useState(cardLinkData.appTitle);
    const menuList = useRef<any>([]);    
    const idxLogin = useRef(-1);    


    const mobile = cardView().mobile;
    const mbisq = 'login';

    const [ getUserByTokenHandler, getUserByTokenRequest] = useLazyQuery(getUserByToken, {
      onCompleted: (data) => {
        if( data.getUserByToken.id === null ) return;
        //console.log('set uid by token:'+data.getUserByToken.id);
        localStorage.setItem('userID',data.getUserByToken.id); 

        //userDatas({...userDatas(), uid:data.getUserByToken.id, fid:data.getUserByToken.user.flowid});
        //console.log( data.getUserByToken.user.id +':' +data.getUserByToken.user.username);
        let index = localStorage.getItem('index') as string;
        userDatas({...userData, uid:data.getUserByToken.id, uname:data.getUserByToken.user.username, index: index, fid:data.getUserByToken.user.flowid});
        getFlowGQLHandler({variables: {id: data.getUserByToken.user.flowid},
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only'});
      }}
    )
  
    const [ getFlowGQLHandler, getFlowGQLRequest] = useLazyQuery(getFlowGQL, {
      onCompleted: (data) => {
        if(data.getFlow.success === true && data.getFlow.flow.cards.length > 0) {
          data.getFlow.flow.cards.map( async(card:any)=>{
            if( card.title === 'User'){

              //for jinju 
              if( cardLink().appTitle === '진주스포츠가치센터'){
                await getUserData();
              }
/*              
              let data = userDatas().data;
              if( userDatas().data.user !== undefined )
                userDatas().nickname = userDatas().data.user.name;
              else  
                userDatas().nickname = card.subtitle;
*/
              let userName = userData.data.user !== undefined?userData.data.user.name:card.subtitle;
              if( userName === '' || userName === null ) userName = userData.uname;

              userDatas({...userData, nickname: userName});
              setName(userName);  
              //console.log(card.data);
            }
          })           
        }
      },
      fetchPolicy: 'network-only',
    });
       
    const [ getFlowGQLMenuHandler, getFlowGQLMenuRequest] = useLazyQuery(getFlowGQL, {
      onCompleted: (data) => {
        if(data.getFlow.success === true && data.getFlow.flow.cards.length > 0) {
          data.getFlow.flow.cards.map( (card:any)=>{
            if( card.category === 'assess'){
              let flowData = JSON.parse(card.data);
              makeMenu(flowData);
              //console.log(card.data);
            }
          })        
        }
      },
      fetchPolicy: 'network-only',
    });

    const makeMenu = async( flowData:any) => {
      if( flowData !== null ){
        var map:any = [];
        let d = Object.entries(flowData.nodes);
        for( const it of d){
          let menuName = "";
          if( (it[1] as any).data === undefined || (it[1] as any).data.body[1] === undefined || (it[1] as any).data.body[1].action === undefined) 
            menuName = "";
          else{
            if( it[0].length > 20 && (it[1] as any).data.body[1].action[3] !== undefined && (it[1] as any).data.body[1].action[3] !== ''){
              menuName = (it[1] as any).data.body[1].action[3];
            }
            if((it[1] as any).data.body[1].action[0] === '../Form/User' || (it[1] as any).data.body[1].action[0] === '../Form/YMLogin' )
              menuName = "User";
          }
          //console.log('it:'+menuName);
          if( it[0].length > 20 )
            map.push([it[0], (it[1] as any).position[0], menuName]);
        }
        map.sort((a:any, b:any) => a[1] - b[1]);

        await Promise.all( map.map((v:any,i:number)=>{
          if( v[2] === "User")  
            idxLogin.current = i;
          else
            menuList.current[i]=v;

        }));

        //console.log('menuList:'+menuList)
      }
    }

    const getAuthInfo = () => {
      
      //if( userData.cfid === "644e1dd36ea318031bbe14bb") return;

      let token = userData.token;
      let tokenKeeped = localStorage.getItem('userToken')

      if( tokenKeeped !== null && token === '' ) token = tokenKeeped;
      //console.log('token:'+token);
      if( token !== '' && token !== null ){
        //console.log('getAuthInfo ---------')
        getUserByTokenHandler({
          variables: {token: token},
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only'
          // pollInterval: 500
        });
      }
    }

    const getMenuInfo = () => {
      menuList.current = [];
      idxLogin.current = -1;
      getFlowGQLMenuHandler({variables: {id: userData.sfid},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'});
    }
  
    useEffect( ()=>{
      userDatas({...userData, nickname: ''});
      setName(cardLinkData.appTitle);
      //getAuthInfo();
    },[]);

    useEffect( ()=>{
      if( userData.sfid !== null && userData.sfid.length > 10)
        getMenuInfo();
    },[userData.sfid]);

    useEffect( ()=>{
      setName(cardLinkData.appTitle);
    },[cardLinkData.appTitle]);

    useEffect( ()=>{
      //console.log('getAuthInfo ----by uid update'+userData.uid)
      if( userData.uid === '' || userData.uid === 'login' || userData.uid === 'signup' )
        getAuthInfo();
    },[userData.uid]); 

    useEffect( ()=>{
      if( userData.nickname === '') setName( cardLinkData.appTitle);
      else setName( userData.nickname);
    },[userData.nickname]);
  
    const onClick = (e:any, show:boolean) =>{
      e.stopPropagation();
      if(cardLink().busy ) return;
      setShowMenu(show);
    }

    const onMenu = (e:any, idx:number) =>{
      if( idx === -1) return;

      if( idx === 1 ){
        // check this code onGoCard('64d882f7c1013f031088cd51');
      }
      else{
        //console.log('sfid:'+userData.sfid+'/lfid:'+userData.lfid);
        userDatas({...userData, popLayer: 'slide', lfid:userData.sfid, lidx : idx, fixed:false});  
      }
      /*
      userDatas({...userDatas(), popLayer: ''});
      const t = setInterval(() => {
        userDatas({...userDatas(), popLayer: 'slide', lfid:userDatas().sfid, lidx : idx});
        //console.log('sfid:'+userDatas().sfid+'/lfid:'+userDatas().lfid);
        clearInterval(t);
      }, 100);
      */
    }

    const onLogout = (e:any) => {
      
      let url = '/gen/'+userData.sfid;
      let curl = window.location.toString();
      let index = ''; //localStorage.getItem('index') as string;
      if( curl.indexOf(index) !== -1 ) url = curl;
      else if( index.length > 9) url = url+'/'+index;

      localStorage.clear();
      userDatas({...userData, popLayer:'', lfid:'', fixed:false, uid:'', uname:'', nickname:'', svcuid:''});
      setName(cardLinkData.appTitle);
      window.open(url, '_self', "");    
    }

    const isLogin = () =>{
      if( userData.uid !== '' || userData.svcuid !== '' ||userData.nickname !== '' )
        return true;
      else
        return false;  
    }
    // g23 : 333x601->280x531 const { width : cWidth, height: cHeight} = useReactiveVar(userDatas); cWidth +'/'+cHeight +'/'+ 

    // userDatas().uid !== ""?userDatas().nickname:mbisq
    if( isFullScreen() ) return(<></>);
    else
    return (
      <div className={`fixed top-0  right-0 flex justify-between  items-stretch opacity-100 bg-theme1 rounded-bl-lg  border-gray-700 text-lg sm:text-base z-50`} style={{ height: `${headerHeight}px`}}>
        <div className={`pl-4 pr-2 flex items-center`}>
          <Button variant="text" onTouchEnd={(e) => mobile?onClick(e,true):{}} onClick={(e) => mobile?{}:onClick(e,true)} sx={{width:'100%'}} size="large" endIcon={<MoreVertIcon sx={{color:'white'}}/>}>
            <Typography sx={{mt:0, color:'#ccc',fontSize: 18, fontWeight: 500}}>{name}</Typography>
          </Button>
        </div>
        <Transition
            show={showMenu}
            className="fixed inset-0 page overflow-hidden"
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="absolute inset-0 bg-black bg-opacity-20 z-100" onTouchEnd={(e) => mobile?onClick(e,false):{}} onClick={(e) => mobile?{}:onClick(e,false)}>
              <Transition.Child
                className="absolute min-w-[175px] bg-white bg-opacity-100 shadow-2xl font-semibold  right-0 rounded-bl-2xl py-2"
                enter=" transtition-transform duration-300"
                enterFrom="translate-x-[175px]"
                enterTo="translate-x-0"
                leave=" transtition-transform duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-[175px]">
                
                <div className="border-b border-gray-300 px-4 py-2" onTouchEnd={(e) => mobile?onMenu(e,idxLogin.current):{}} onClick={(e) => mobile?{}:onMenu(e,idxLogin.current)}>
                  {isLogin()?userData.nickname:(idxLogin.current === -1)?'':'login or signup'}
                </div>
                
                {isLogin()&&
                <div className="block px-4 py-2" onTouchEnd={(e) => mobile?onLogout(e):{}} onClick={(e) => mobile?{}:onLogout(e)}>
                  Logout
                </div>}

                { menuList.current.map( (v:any,i:number) => {
                  if( v[2] === '') return;
                  else
                  return <div className="block px-4 py-2" key={i} onTouchEnd={(e) => mobile?onMenu(e,i):{}} onClick={(e) => mobile?{}:onMenu(e,i)}>
                  {v[2]}
                  </div>
                  })}
                  <div className="block px-4 py-1">
                  <Typography sx={{mt:0, color:'#999d',fontSize: 14, fontWeight: 500}}>mbisquare v.1.3.23</Typography>
                </div>
              </Transition.Child>
            </div>
          </Transition>
      </div>
    )
  },
)

/**
            <button className="bg-white hover:bg-gray-100 px-4 border border-gray-600 rounded shadow" 
            style={{height:'30px'}} 
            onTouchEnd={(e) => mobile?onClick(e,true):{}} onClick={(e) => mobile?{}:onClick(e,true)}>
            {name}
            </button>

 */