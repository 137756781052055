import {Fragment, useEffect, useRef, useState} from "react";
import clonedeep from 'lodash.clonedeep'

import { useSpring, animated, useSprings, config } from '@react-spring/web';

import BaseCard from "../Card/BaseCard";
//import LinkCard from "../Card/LinkCard";

import "./style.css";
import useResize from "../../hook/useResize";
import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { cardLink, cardView, connectorMQTT, resultDatas, taskDatas, userDatas } from "@app/hoc/cache";
import { Button } from "@mui/material";
import { getFlowGQL } from "@app/hoc/gql";
import CloseButton from "./CloseButton";

const springConfig = {
    tension: 350,
    friction: 60,
    precision: 0.001,
    velocity: 0.001,
    clamp: true,
  };
  
const CARD_STACK_MARGIN = 3;
const DURATION = 300;
const BASE_Z_INDEX = 20;

const from = (i: number) => ({
y: -500,
scale: 1,
x: 0,
rotateZ: 0,
transformOrigin: "center",
zIndex: i + BASE_Z_INDEX,
});

interface ICard {
  id: string, title: string; subtitle: string; data: string; category: string; 
  linkto: string; img: string;
}

interface FlowProps {
    flow: {
      id?: string;
      cards?: Array<ICard>;
    };
  }


const LayerFlow = (props: FlowProps) => {
    const { flow } = props;

    const [prevIndex, setPrevIndex] = useState(0);

    //let cards:any[] = [];
    //let idxList:any[] = [];
    const flowcards = useRef([]); 
    const [cards,setCards] = useState<ICard[]>([]);
    //const idxList = useRef([]);
    const idxList = useRef<{ [key: string]: any }>({});    
    const viewCards = useRef([0,1]);
    const idxCards = useRef(0);

    //let cWidth = cardView().width;
    //let cHeight = cardView().height;
    //const [cWidth, setWidth] = useState(cardView().width);
    //const [cHeight, setHeight] = useState(cardView().height);

    //setWidth(cardView().width);
    //setHeight(cardView().height);

    //const { width : cWidth, height: cHeight } = useResize();
    const { width : cWidth, height: cHeight } = userDatas();

    //console.log('stackflow resize ************** ('+cWidth+'x'+cHeight+')');

    const [ getFlowGQLHandler, getFlowGQLRequest] = useLazyQuery(getFlowGQL, {
      onCompleted: (data) => {
        if(data.getFlow.success === true && data.getFlow.flow.cards.length > 0) {
          flowcards.current = clonedeep(data.getFlow.flow.cards);
          initFlow();
          /*
          data.getFlow.flow.cards.map( (card:any)=>{
            if( card.title === 'User'){
              userDatas({...userDatas(), ncikname: card.subtitle});
              //console.log(card.data);
            }
          })
          */           
        }
      },
    });
        
    useEffect(()=>{
      //console.log('resize:'+cHeight);
      let idx = idxList.current[cardLink().cardid];
      if( idx !== undefined )
        resizeCards(idx);
    },[cHeight]);
/*
    useEffect(()=>{
      //console.log('XXXXXXXXXXXXXXXXXXXXXX get goCard:'+goCard)
      if( goCard !== '')
        onSwitch(goCard);
    },[goCard]);
*/
    useEffect(()=>{
      //console.log("**********************flow.id:"+flow.id);
      getFlowGQLHandler({variables: {id: flow.id},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'});
      
    },[flow.id]);

    const initFlow = async () => {
      //console.log('*******initFlow')
      let bStart = false;
      let startCardID = "";
      let flowData:any = null;
      await Promise.all(flowcards.current.map( (v:ICard,i) => {

        if( v.subtitle !== null && v.subtitle.at(0) === '['){
          if( v.subtitle.at(2) === '0'){
            startCardID = v.id;
            bStart = true;
          }
          else
            cardLink().goCards[v.subtitle.at(2) as any] = v.id;
  
           v.subtitle = v.subtitle.substring(4);  
        }
        else if( !bStart && (v.subtitle === "시작" || v.subtitle === "Start Card") ){
          startCardID = v.id;
          bStart = true;
        }

        if( v.category === "assess" ){
          //cardLink().editcardid = v.id;
          flowData = JSON.parse(v.data);
        }
      }));

      await Promise.all(flowcards.current.map( (v:ICard,i) => {
        if( v.category === "switch" || v.category === "link" || v.category === "message" || v.category === "action"){
          if(v.data === ''){
            cards.unshift(v);
            return true;
          }

          let outputData = JSON.parse(v.data);
          if( v.id === startCardID ){
              cards.push(v);
              //cardLink().cardid = v.id;
              //cardLink().outputs = outputData.actions[0][1].rules;
          }
          else    
            cards.unshift(v);
          
          // apply flow link  
          //console.log( outputData.cases);
          if( flowData !== null ){
            outputData.cases.map( (connect:any) => {
              if( flowData.nodes[v.id].outputs[connect.id] === undefined){
                //console.log(connect.id +':'+flowData.nodes[v.id].outputs);
              }
              //console.log( connect.card + '->' + flowData.nodes[v.id].outputs[connect.id].connections[0].node);
              if( flowData.nodes[v.id].outputs[connect.id] !== null && flowData.nodes[v.id].outputs[connect.id].connections[0] !== undefined && flowData.nodes[v.id].outputs[connect.id].length !== 0)
                connect.card = flowData.nodes[v.id].outputs[connect.id].connections[0].node;  
            })
            v.data = JSON.stringify(outputData);
            //console.log( flowData.nodes[v.id].outputs);  
          }
        }
        return true;    
      }));

      viewCards.current = [cards.length-1,0];

      await Promise.all(cards.map((card:ICard, index) => {
          if( card.id !== null ){
            idxList.current[card.id] = index;
            idxCards.current += 1;
          }
              
          return true;    
      }));
      setPrevIndex( cards.length - 1);

    }

    const [wrapperSpring, setWrapperSpring] = useSpring(() => ({
      width: `${cWidth}px`,
      height: `${cHeight}px`,
    }));

    // initial operation
    const [switchCardSprings, setSwitchCardSprings] = useSprings(
      2,
      (index) => {
        //console.log('* setSwitchCardSprings  ----------------:'+index);
        //console.log('stackflow init ************** ('+cWidth+'x'+cHeight+')');
        const idx = index; //order.current.indexOf(index);
        //console.log("initial:"+index+"/"+idx+"/"+cards.length+"*"+order.current.indexOf(index));
        return {
          from: { ...from(idx), y: cHeight },
          to: async (animate) => {
            await animate({
              y: cHeight + 15 + idx * CARD_STACK_MARGIN,
              rotateZ: 0,
              zIndex: idx + BASE_Z_INDEX,
              immediate: (key: string) => key === "zIndex",
              config: { duration: DURATION * 0.1 },
            });
            await animate({
              y: idx === 0 ? 0 : cHeight + 15 + idx * CARD_STACK_MARGIN,
              rotateZ: 0, //idx === cards.length - 1 ? -3 : idx % 2 ? -1 : 1,
              zIndex: idx + BASE_Z_INDEX,
              immediate: (key: string) => key === "zIndex",
              delay: DURATION * 0.7,
              config: { ...config.gentle },
            });
          },
          immediate: (key: string) => key === "zIndex",
          config: {
            duration: DURATION * 0.1,
          },
        };
      }
    );
    

    const resizeWrapper = () => {
      //console.log('stackflow resizeWrapper ************** ('+cWidth+'x'+cHeight+')');
      setWrapperSpring.stop();
      setWrapperSpring.start({
        from: {
          height: `${cHeight}px`,
        },
        to: async (animate) => {
          await animate({
            height: `${cHeight + CARD_STACK_MARGIN * 1.2}px`,
          });
          await animate({
            height: `${cHeight}px`,
          });
        },
        config: {
          ...springConfig,
          duration: DURATION,
        },
      });
    };

    const resizeCards = (index:any) => {
      //console.log('stackflow resizeCards ************** ('+cWidth+'x'+cHeight+')');
      setSwitchCardSprings.stop();
      setSwitchCardSprings.start((itemIndex) => {
        //console.log("click:"+cardIndex+"/c:"+index+"/p:"+prevIndex+"/"+itemIndex);
        if (itemIndex !== 0 ){
          //console.log('card open : '+cards[index].id);
          return {
            to: async (animate:any) => {
              await animate( {
                y: cHeight+5,
                rotateZ: 0,
                config: springConfig,
              }
              )
            },
            config: { duration: DURATION * 0.8 },
          }
        }
      });
    };
  
    const handleClick = (cardIndex: number) => {
      //console.log('stackflow handleClick ************** ('+cWidth+'x'+cHeight+')');
      // click animation logic
      const index = cardIndex; //order.current.indexOf(cardIndex);

      if (index <= idxCards.current ) {
        resizeWrapper();
        setSwitchCardSprings.stop();
        setSwitchCardSprings.start((itemIndex) => {
        //console.log("click:"+cardIndex+"/c:"+index+"/p:"+prevIndex+"/"+itemIndex);

          if (itemIndex === 1 ){
            return {
              from: {
                y: 0,
                rotateZ: 0,
                config: { duration: DURATION * 0.1 },
              }, 
              to: async (animate:any) => {
                await animate( {
                  y: cHeight + 15 + prevIndex * CARD_STACK_MARGIN, // - prevIndex*CARD_STACK_MARGIN,
                  rotateZ: 0,
                  config: springConfig,
                }
                )
              },
              config: { duration: DURATION * 0.8 },
            }
          } 
          else if (itemIndex === 0 ){
            //console.log('card open : '+cards[index].id);
            //connectorMQTT().cid = cards[index].id;
            return {
              from: {
                y: cHeight + 15 + index * CARD_STACK_MARGIN,
                rotateZ: 0,
                config: { duration: DURATION * 0.1 },
              }, 
              to: async (animate:any) => {
                await animate( {
                  y: 0,
                  rotateZ: 0,
                  config: springConfig,
                }
                )
              },
              config: { duration: DURATION * 0.8 },
            }
          } 
        });
      }
      setPrevIndex(index);
    };    

    const onSwitch = (cardId: string) => {
        
      if( cardId.length < 24){
        //const { id : id, data: data } = useReactiveVar(resultDatas);
        const id = taskDatas().import.id;
        const data = taskDatas().import.data;
        for( let i = 0 ; i < data.length; i++){
          if( data[i].category === 'switch' ){
            cards.push(data[i]);
            idxList.current[data[i].id as string] = idxCards.current;
            idxCards.current += 1;  
            if( data[i].subtitle === 'Start Card'){
              cardId = data[i].id;
            }
              
          } 
        }
      }

      if( cardId !== '' && cardLink().cardid !== cardId){
        let idx = idxList.current[cardId];
        //console.log('card switch:'+cardId+"-"+idx+"/"+cardLink().cardid);
        if( idx !== undefined ){
          viewCards.current = [idx,viewCards.current[0]];
          cardLink().cardid = cardId;
          handleClick(idx);
        }
        else{
          cardId = taskDatas().import.out;
          idx = idxList.current[cardId];
          viewCards.current = [idx,viewCards.current[0]];
          cardLink().cardid = cardId;
          handleClick(idx);
        }
      }
    }

    const closeLayer = (e:any) => {
      e.preventDefault();
      userDatas({...userDatas(), popLayer: '', lidx: 0});
    }

    return (
      <Fragment>
        {(cards.length !== 0 ) && <animated.div style={wrapperSpring} className="wrapperLayer">
          <CloseButton doClick={closeLayer}/>
          {switchCardSprings.map((styles, index) => (
            <animated.div
              style={{
                ...styles,
                position: "absolute",
                width: cWidth,
              }}
              key={"card-" + index}
            >
              <BaseCard card={cards[viewCards.current[index]]} ctype={2} width={cWidth} height={cHeight} onCaseSelect={(idx:any) => onSwitch(idx)}/>
            </animated.div>
          ))}
        </animated.div>}
      </Fragment>
    )
}
/**
 *             { cards[index].category == "switch" && <SwitchCard card={cards[index]} onCaseSelect={(idx:any) => onSwitch(idx)}/>}
            { cards[index].category == "link" && <LinkCard card={cards[index]} onCaseSelect={(idx:any) => onSwitch(idx)}/>}
            { cards[index].category == "message" && <LinkCard card={cards[index]} onCaseSelect={(idx:any) => onSwitch(idx)}/>}


 *             onClick={() => handleClick(index)}
            { cards[index].category === "switch" &&<SwitchCard card={cards[index]} onCaseSelect={(idx:any) => onSwitch(idx)}/>}

 */
export default LayerFlow;
