import { useNavigate, useSearchParams } from 'react-router-dom';
import { KAKAO_REDIRECT_URL } from '@app/constants/YMData';
import { useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  createYMExecuterGQL,
  createYMUserGQL,
  getUserByName,
  getYMExecuterGQL,
  getYMUserGQL,
  logInWSocialM,
  signUpSocialM,
} from '@app/hoc/gql';
import { UserData } from '@app/callbacks/NaverCallbackPage';
import { isKeepLoginBySocialLogin, userDatas } from '@app/hoc/cache';

export default function KakaoCallbackPage() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const getTokenUrl = `https://kauth.kakao.com/oauth/token?grant_type=authorization_code&client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${KAKAO_REDIRECT_URL}&code=${code}`;
  const navigate = useNavigate();

  const [getUserByEmail] = useLazyQuery<UserData>(getUserByName);
  const [logInWithSocialMutation] = useMutation(logInWSocialM);

  const [signUpWithSocialMutation] = useMutation(signUpSocialM);

  const [getExecuterHandler, getExecuterRequest] = useLazyQuery(getYMExecuterGQL, {});
  const [getYMUserHandler] = useLazyQuery(getYMUserGQL, {});

  const [createExecuterHandler, createExecuterRequest] = useMutation(createYMExecuterGQL, {});
  const [createYMUserHandler] = useMutation(createYMUserGQL, {});

  const onLogin = async (email: string, id: string, accessToken: string, name: string) => {
    const loginResult = await logInWithSocialMutation({
      variables: { email, socialId: String(id), token: accessToken, type: 'kakao' },
    });

    if (loginResult.data.logInWSocial.success) {
      userDatas({ ...userDatas(), uid: 'login', token: loginResult.data.logInWSocial.token, type: 'kakao' });
      const userCategory = localStorage.getItem('userCategory');
      navigate('/');

      /*
      if (userCategory === 'parent') {
        localStorage.setItem('parentRecentLogin', 'kakao');

        const getYMUser = await getYMUserHandler({
          variables: { uid: loginResult.data.logInWSocial.id },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
        });

        if (getYMUser.data.getYMUser.success === false) {
          const createUser = await createYMUserHandler({
            variables: {
              input: {
                user: loginResult.data.logInWSocial.id,
                name: name,
                category: 'parent',
                data: JSON.stringify({}),
              },
            },
          });
        } else {
        }
      } else {
        localStorage.setItem('recentLogin', 'kakao');

        const getUser = await getExecuterHandler({
          variables: { uid: loginResult.data.logInWSocial.id },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
          // pollInterval: 500
        });

        console.log(getUser.data.getYMExecuter, 'getUser');

        if (getUser.data.getYMExecuter.success === false) {
          const createExecuter = await createExecuterHandler({
            variables: {
              input: {
                user: loginResult.data.logInWSocial.id,
                name: name,
                category: 'exam',
                data: JSON.stringify({}),
              },
            },
          });
        } else {
        }
      }

      const isKeepLoginSocial = JSON.parse(localStorage.getItem('isKeepLoginBySocialLogin')!);
      if (isKeepLoginSocial) localStorage.setItem('userToken', loginResult.data.logInWSocial.token);

      localStorage.removeItem('isKeepLoginBySocialLogin');

      navigate('/');
      */
    } else {
      // alert('서버 에러가 발생했습니다. 로그인페이지로 이동합니다.');
      navigate('/');
    }
  };
  const unLink = async (accessToken: string) => {
    await fetch('https://kapi.kakao.com/v1/user/unlink', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((resp) => resp.json());
  };

  const bootstrap = async () => {
    const propertyData = new URLSearchParams({
      property_keys: JSON.stringify(['kakao_account.name']),
    });

    try {
      const { access_token, id_token } = await fetch(getTokenUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
        body: propertyData,
      }).then((resp) => resp.json());

      const {
        kakao_account: { email, name },
        id,
      } = await fetch('https://kapi.kakao.com/v2/user/me', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }).then((resp) => resp.json());

      if (!name) {
        await unLink(access_token);
        alert('이름 정보를 가져오지 못했습니다. 로그인페이지로 이동합니다.');
        navigate('/');
      }

      console.log(id_token, ' id_token');

      if (email && name && id) {
        try {
          const { data } = await getUserByEmail({
            variables: { username: email },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only',
          });
          if (!data?.getUserByName.success) {
            const signUpResult = await signUpWithSocialMutation({
              variables: { email, username: name, socialId: String(id), token: access_token, type: 'kakao' },
            });
            console.log(signUpResult.data, 'signup data');
            if (signUpResult.data.signUpWSocial.success) {
              console.log('회원가입 성공!');
              await onLogin(email, id, access_token, name);
            }
          } else {
            await onLogin(email, id, access_token, name);
          }
        } catch (err) {
          console.log(err);
          // alert('서버 에러가 발생했습니다. 로그인 페이지로 이동합니다.');
          // navigate('/');
        }
      } else {
        console.log('email 없음');
        // alert('서버 에러가 발생했습니다. 로그인 페이지로 이동합니다.');
        // navigate('/');
      }

      console.log(access_token, email, name, id);
    } catch (err) {
      console.log(err, 'err');
    }
  };

  useEffect(() => {
    bootstrap();
  }, []);

  return <h1>Loading...</h1>;
}
