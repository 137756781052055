import { useNavigate, useSearchParams } from 'react-router-dom';

import { useEffect } from 'react';
import { GOOGLE_REDIRECT_URL } from '@app/constants/YMData';
import { useLazyQuery, useMutation } from '@apollo/client';
import { UserData } from '@app/callbacks/NaverCallbackPage';
import {
  createYMExecuterGQL,
  createYMUserGQL,
  getUserByName,
  getYMExecuterGQL,
  getYMUserGQL,
  logInWSocialM,
  signUpSocialM,
} from '@app/hoc/gql';
import { isKeepLoginBySocialLogin, userDatas } from '@app/hoc/cache';

export default function GoogleCallbackPage() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const TOKEN_ENDPOINT = 'https://accounts.google.com/o/oauth2/token';

  const navigate = useNavigate();
  const [getUserByEmail] = useLazyQuery<UserData>(getUserByName);
  const [logInWithSocialMutation] = useMutation(logInWSocialM);

  const [signUpWithSocialMutation] = useMutation(signUpSocialM);
  const [getExecuterHandler, getExecuterRequest] = useLazyQuery(getYMExecuterGQL, {});
  const [getYMUserHandler] = useLazyQuery(getYMUserGQL, {});

  const [createExecuterHandler, createExecuterRequest] = useMutation(createYMExecuterGQL, {});
  const [createYMUserHandler] = useMutation(createYMUserGQL, {});

  const onLogin = async (email: string, id: string, accessToken: string, name: string) => {
    const loginResult = await logInWithSocialMutation({
      variables: { email, socialId: id, token: accessToken, type: 'google' },
    });

    if (loginResult.data.logInWSocial.success) {
      const userCategory = localStorage.getItem('userCategory');
      if (userCategory === 'parent') {
        localStorage.setItem('parentRecentLogin', 'google');
        const getYMUser = await getYMUserHandler({
          variables: { uid: loginResult.data.logInWSocial.id },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
        });

        if (getYMUser.data.getYMUser.success === false) {
          const createUser = await createYMUserHandler({
            variables: {
              input: {
                user: loginResult.data.logInWSocial.id,
                name: name,
                category: 'parent',
                data: JSON.stringify({}),
              },
            },
          });
        } else {
        }
      } else {
        console.log(loginResult.data.logInWSocial.id, 'loginSocials');

        localStorage.setItem('recentLogin', 'google');
        const getUser = await getExecuterHandler({
          variables: { uid: loginResult.data.logInWSocial.id },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
          // pollInterval: 500
        });

        console.log(getUser.data.getYMExecuter, 'getUser');

        if (getUser.data.getYMExecuter.success === false) {
          const createExecuter = await createExecuterHandler({
            variables: {
              input: {
                user: loginResult.data.logInWSocial.id,
                name: name,
                category: 'exam',
                data: JSON.stringify({}),
              },
            },
          });
        } else {
        }
      }

      const isKeepLoginSocial = JSON.parse(localStorage.getItem('isKeepLoginBySocialLogin')!);
      if (isKeepLoginSocial) localStorage.setItem('userToken', loginResult.data.logInWSocial.token);
      //

      userDatas({ ...userDatas(), uid: 'login', token: loginResult.data.logInWSocial.token, type: 'google' });

      localStorage.removeItem('isKeepLoginBySocialLogin');

      navigate('/');
    } else {
      alert('서버 에러가 발생했습니다. 로그인페이지로 이동합니다.');
      navigate('/');
    }
  };

  const bootstrap = async () => {
    if (!code) {
      navigate('/');
      return;
    }
    const formData = new URLSearchParams({
      code,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '',
      client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET ?? '',
      redirect_uri: GOOGLE_REDIRECT_URL,
      grant_type: 'authorization_code',
    });

    const { access_token, id_token } = await fetch(TOKEN_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData,
    }).then((resp) => resp.json());

    const { email, name, id } = await fetch(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`,
      {},
    ).then((resp) => resp.json());

    console.log(email, 'result', name, id, access_token, 'idtoken', id_token);

    if (email && name && id) {
      try {
        const { data } = await getUserByEmail({
          variables: { username: email },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
        });

        console.log(data, 'datatatatat');

        if (!data?.getUserByName.success) {
          const signUpResult = await signUpWithSocialMutation({
            variables: { email, username: name, socialId: id, token: id_token, type: 'google' },
          });

          if (signUpResult.data.signUpWSocial.success) {
            await onLogin(email, id, id_token, name);
          }
        } else {
          await onLogin(email, id, id_token, name);
        }
      } catch (err) {
        alert('서버 에러가 발생했습니다. 로그인 페이지로 이동합니다.');
        navigate('/');
      }
    } else {
      alert('서버 에러가 발생했습니다. 로그인 페이지로 이동합니다.');
      navigate('/');
    }
  };

  useEffect(() => {
    bootstrap();
  }, []);

  return <h1>Loading....</h1>;
}
