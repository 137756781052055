import clonedeep from 'lodash.clonedeep'

import {
    useMutation,
    useQuery,
    useLazyQuery,
    useReactiveVar,
} from "@apollo/client";


import "./style.css";
import StackFlow from "./StackFlow";
import DeckFlow from "./DeckFlow"
import CarouselFlow from "./CarouselFlow";
import { connectorMQTT, userDatas} from "@app/hoc/cache";

import GenFlow from "./GenFlow";
import { getFlowGQL } from "@app/hoc/gql";
import LayerFlow from "./LayerFlow";
import LayerDeckFlow from "./LayerDeckFlow";
import LayerSlideFlow from "./LayerSlideFlow";
import { setSize } from '@app/constants/YMData';

interface FlowProps {
    flow: {
      id?: string;
      type?:string;
      meta?: Array<{ title: string; subtitle: string }>;
    };
    goCard:string;
  }

// --------------------------------------------------- 


const Flow = (props: FlowProps) => {
    const { flow,goCard } = props;

    const userData = useReactiveVar(userDatas); // refresh UI by value

    const { loading, error, data, refetch, networkStatus } = useQuery(
        getFlowGQL,
        {
          variables: { "id": flow.id },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only'
          // pollInterval: 500
        },      
    );
    
    if (networkStatus === 4) return <p>Refetching!</p>;
    if (loading) return <p>loading</p>;
    if (error) return <p>Error!: {error.message}</p>;
    if (data.getFlow.flow === null ) return <p>Error!: Wrong Flow ID</p>;    
    
    const cards = clonedeep(data.getFlow.flow.cards);
    

    connectorMQTT().fid = data.getFlow.id;
    if( flow.type === 'flowfull'){
        const doc = document.documentElement as any;
    
        //console.log(typeof doc.requestFullscreen)
        if (typeof doc.requestFullscreen === "function") {
          doc.requestFullscreen()
        }
      
        //console.log(typeof doc.webkitRequestFullscreen)
        if (typeof doc.webkitRequestFullscreen === "function") {
          doc.webkitRequestFullscreen()
        }    
      } 

    if (flow.type === "slide")
        return <CarouselFlow flow={{cards:cards}}  goCard={goCard}/>
    else if (flow.type === "history")
        return <DeckFlow flow={{cards:cards}}/>
    else if (flow.type === "gen")// flow type
        return <>
        {userDatas().popLayer === 'deck' && <LayerDeckFlow flow={{id:userDatas().fid}} />}
        {userDatas().popLayer === 'stack' && <LayerFlow flow={{id:userDatas().fid}} />}
        {userDatas().popLayer === 'slide' && <LayerSlideFlow flow={{id:userDatas().lfid, idx:userDatas().lidx}}/>}
        <GenFlow flow={{cards:cards}} goCard={goCard}/>
        </>
    else // flow type
        return <StackFlow flow={{cards:cards}} goCard={goCard}/>
}

export default Flow;
