import debounce from 'lodash.debounce';
import {
    useState, useMemo, useEffect, useCallback,
  } from 'react';
import { isMobile} from 'react-device-detect';  
import { cardView, userDatas } from '@app/hoc/cache';
import { isFullScreen } from '../components/Exam/containers/GameFullScreen';
import { setSize } from '@app/constants/YMData';
  
type ScreenSizeType = {
      height: number;
      width: number;
}
export const useResize = (): ScreenSizeType => {
    const [height, setHeight] = useState(cardView().height);
    const [width, setWidth] = useState(cardView().width);
  
    let size = useMemo(() => ({ height, width }), [height, width]);
    const memoResizeHandler = useCallback(ResizeHandler, []);
    //const memoResizeHandler = debounce( ()=>resizeHandler,200);
  
    useEffect(() => {
      ResizeHandler({type:'load'});
      console.log('add resize handler **************');
      window.addEventListener('load', debounce(memoResizeHandler,20))
      window.addEventListener('resize', debounce(memoResizeHandler,20));
      document.addEventListener('fullscreenchange', debounce(memoResizeHandler,20));
      return () => {
        //console.log('remove resize handler **************');
        window.removeEventListener('load', debounce(memoResizeHandler,20))
        window.removeEventListener('resize', debounce(memoResizeHandler,20));
        document.removeEventListener('fullscreenchange', debounce(memoResizeHandler,20));
      };
    }, [memoResizeHandler]);

    function ResizeHandler(e:any) {
      if(userDatas().reSize === false) return;
      console.log("ResizeHandler:"+e.type);
      size = setSize(); // resizeHandler( e.type === 'fullscreenchange') as ScreenSizeType;
      setHeight(size.height);
      setWidth(size.width);    
    }
    return size;
  };

const resizeHandler= (force=false) => {
  //console.log('call resizeHandler ****** '+userDatas().reSize);
  //if(userDatas().reSize === false && !force) return { height:cardView().height, width: cardView().width };;
  //console.log('call resizeHandler in ************** ');

  //console.log("WINDOW 2(wxh): "+window.innerWidth+" x "+window.innerHeight);
  let hoffset = 0;
  let voffset = 0;

  //let isMobile = (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
  if( isMobile !== true ){
    hoffset = 0;
    voffset = 0;
  }
  else{
    //console.log("mobile device");
    hoffset = 0;
    voffset = 0;
  }
  //after 221014----------------------------------------
  let mode = 1; // width 기준
  let ratio = 1.56; //1.618 golden ratio card 1.603/1.56, A4 1.414 / 0.64

  if(window.innerWidth > window.innerHeight)
    ratio = (window.innerHeight / window.innerWidth)-0.05;

  let cWidth = window.innerWidth - window.innerWidth/10;
  let cHeight = (window.innerWidth - hoffset)*ratio+voffset; //+100; fullscreen compenation

  if( localStorage.getItem('type') !== 'exam' ){ // height 기준
    cHeight = window.innerHeight - 70;
    cWidth = (cHeight - voffset)/ratio + hoffset - 60;
    if( cWidth+30 > window.innerWidth) cWidth = window.innerWidth - 60;
    mode = 2;
  }

  const bFull = isFullScreen();
  cHeight = (cHeight - 0)|0 + (bFull?50:0);
  cWidth = (cWidth + 0)|0;  


  ratio = (cHeight*100 / cWidth)|0;
  /*

  if( cHeight > cWidth*2 ){
    cHeight = cWidth*ratio;
  }
  if( isMobile && userDatas().ratio*1.5 < ratio){
    //console.log('ratio change:'+isMobile+':'+ userDatas().ratio + '->' + ratio);
    return;
  }
  */

  userDatas({...userDatas(), width:cWidth, height:cHeight , ratio: ratio});
  cardView({ mobile:isMobile, width:cWidth, height:cHeight, src: cardView().src});
  console.log('call resizeHandler out ************** ('+cWidth+'x'+cHeight+')');
  return { height:cHeight, width: cWidth };
}

export default useResize;

