import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Box, Stack } from '@mui/system';
import { Typography } from '@mui/material';

// Augment the palette to include a salmon color
declare module '@mui/material/styles' {
  interface Palette {
    deep: Palette['primary'];
    light: Palette['primary'];
    bold: Palette['primary'];
    dtext: Palette['text'];
  }

  interface PaletteOptions {
    deep?: PaletteOptions['primary'];
    light?: PaletteOptions['primary'];
    bold?: PaletteOptions['primary'];
    dtext?: PaletteOptions['text'];
  }
}

// Update the Button's color options to include a salmon option
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    deep: true;
    light: true;
    bold: true;
    dtext: true;
  }
}

const dTheme = createTheme({});
export const theme = createTheme(dTheme,{
  // Custom colors created with augmentColor go here
  palette: {
    deep: dTheme.palette.augmentColor({
      color: {
        main: '#556AEB',
      },
      name: 'deep',
    }),
    light: dTheme.palette.augmentColor({
      color: {
        main: '#EBEFFF',
        contrastText: '#556AEB'
      },
      name: 'light',
    }),
    bold: dTheme.palette.augmentColor({
      color: {
        main: '#EBEBEB',
        contrastText: '#555555'
      },
      name: 'bold',
    }),
    dtext: dTheme.palette.augmentColor({
      color: {
        main: '#6C757D',
        light: '#EBEFFF',
        dark: '#A29415',
        contrastText: '#556AEB'
      },
      name: 'dtext',
    }),
  },
});

export const YMButton = (props: any) => {
  const { children, ...other } = props;
  return (
    <ThemeProvider theme={theme}>
      <Button {...other} >{children}</Button>
    </ThemeProvider>
  );
};

/*
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { purple } from '@mui/material/colors';

export const YMButton2 = styled(Button) ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  //color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#556AEB',
  borderColor: '#556AEB',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: purple[700],
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});

export const YMButton = styled(Button)<{bgcolor:string;bdcolor:string;}>`
background-color: ${(props) => props.bgcolor};
border-color: ${(props) => props.bdcolor};
color: ${(props) => props.bdcolor};
border: '1px solid';
`;

export const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
    },
  }));
*/  
/*  
  export default function CustomizedButtons() {
    return (
      <Stack spacing={2} direction="row">
        <ColorButton variant="contained">Custom CSS</ColorButton>
        <BootstrapButton variant="contained" disableRipple>
          Bootstrap
        </BootstrapButton>
      </Stack>
    );
  }
  */