import { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import NoSsr from '@mui/material/NoSsr';
import { useTheme } from '@mui/material/styles';
import { cardView } from "@app/hoc/cache";

interface ResizableFrameProps {
  src: string;
  audio: string;
  title: string;
  path: string;
  cid: string;
  iframeStyles?: Record<string, unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

const viewportWidthMap = {
  desktop: '100%',
  tablet: 850,
  mobile: 480,
};

const viewportInitialHeight = 600;

const IFrame = ({
  src,
  audio,
  title,
  path,
  cid,
  iframeStyles = {},
  ...rest
}: ResizableFrameProps): JSX.Element => {
  const theme = useTheme();
  const iframeEl = useRef<HTMLIFrameElement>(null);
  const isMobile = (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth*(isMobile?0.71:0.99)); //'desktop');
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight*(isMobile?0.66:0.76));
  const [random, setRandom] = useState(0);
  const ipath = `${src.replace(/:cid/i, cid)}`;
  const cwidth = cardView().width;
  const cheight = cardView().height;
  const audioPath = audio;

  const setIframeHeight = () => {
    /*
    if (
      iframeEl &&
      iframeEl.current &&
      iframeEl.current.contentWindow &&
      iframeEl.current.contentWindow.document &&
      iframeEl.current.contentWindow.document.documentElement
    ) {
      const height =
        iframeEl.current.contentWindow.document.documentElement.offsetHeight ||
        viewportInitialHeight;
      setViewportHeight(height);
    }
    */
  };

  useEffect(() => {
    setIframeHeight();
  }, [viewportWidth]);

  useEffect(() => {
    setRandom(random + 1);
  }, [theme.palette.mode]);

  const handleResizeBtnClick = (width:any) => {
    if (width === viewportWidth) {
      return;
    }

    const values = Object.keys(viewportWidthMap);
    if (values.indexOf(width) === -1) {
      return;
    }

    setViewportWidth(width);
  };

  const playAudio = async() => {
    if(audioPath === undefined ) return;
    //console.log('play audio(3):'+audioPath)
    /*
    //const audioPlay = new Audio('https://flow.liflo.io'+audio); // ${item.audioRightWord}
    const audioPlay = new Audio('/assets/5-1.웃는소리.mp3'); // ${item.audioRightWord}
    audioPlay.play();
    */
    //let sound = await import(audioPath);
    const audioP = new Audio(audioPath)
    audioP.play()
  }

  

  let isVideo = ipath.indexOf('player_id=20') > 0 ? true : false ;
  /*
  let ifstyle = `{
    position:'absolute',
    top:-300,
    left:-180,
    height: '200%',
    width: '200%',
    border: 'none',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    ...iframeStyles,
  }`;
  let ifwidth = cwidth-16;
  let ifheight = cheight-80;
  */
  return (
        <Box component="div"
          marginTop={'0px'}
          border={`1px solid ${theme.palette.divider}`}
          borderRadius={2}
          width={cwidth+20}
          height={cheight+10}
          sx={{ position: "absolute", left: "-15px", top:"-5px"}}
        >
            <NoSsr>
              <Box component="div"
                width={cwidth+20}
                height={'100%'}
                display={'flex'}
                sx={{flexDirection: 'column',
                  alignItems: 'center'}}
              >
                {isVideo && <iframe
                  loading="lazy"
                  ref={iframeEl}
                  allow="camera *;microphone *"
                  key={random}
                  src={ipath}
                  title='mbisq'
                  onLoad={() => setTimeout(() => setIframeHeight())}
                  style={{
                    position:'absolute',
                    top:-cheight/2-58,
                    left:-cwidth/2,
                    height: '200%',
                    width: '200%',
                    border: 'none',
                    borderBottomLeftRadius: 8,
                    borderBottomRightRadius: 8,
                    ...iframeStyles,
                  }}
                />}
                {!isVideo && <iframe
                  loading="lazy"
                  ref={iframeEl}
                  allow="camera *;microphone *"
                  key={random}
                  src={ipath}
                  title='mbisq'
                  onLoad={() => setTimeout(() => setIframeHeight())}
                  style={{
                    height: cheight+5,
                    minHeight: 400,
                    width: cwidth+5,
                    border: 'none',
                    borderBottomLeftRadius: 8,
                    borderBottomRightRadius: 8,
                    ...iframeStyles,
                  }}
                />}

              </Box>
            </NoSsr>
      </Box>
  );
};

// maxWidth={viewportWidthMap[viewportWidth]}
export default IFrame;

// style="position:absolute;top:0;left:0;width:100%;height:100%;"
/**
 *                     height: cheight-80,
                    minHeight: 400,
                    width: cwidth-16,

                    <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/746842953?h=247065583d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="1"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>

 */